import { imgCompany, imgIndividual, imgIRA, imgTrust } from 'assets/auth'
import { AccountType, AccountTypeText, InputType } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'stories/components'
import { InputConvert, RenderInput, validateInputs } from 'utils'

import { IRAOptions } from './Section'

export const accountTypeOptions: Record<string, any> = {
  individual: {
    title: 'Individual',
    icon: imgIndividual,
    description: <>Taxable account for individuals</>,
  },
  ira: {
    title: 'Self Directed IRA',
    icon: imgIRA,
    description: <>Tax-deffered account for individuals</>,
  },
  trust: {
    title: 'Trust',
    icon: imgTrust,
    description: <>For individuals/trustees with an established trust</>,
  },
  company: {
    title: 'Entity',
    icon: imgCompany,
    description: <>Invest a company's assets using a corporate or alternate tax ID</>,
  },
}

export const OwnershipFormOptions: Record<string, string> = {
  Individual: 'Individual Ownership',
  Community: 'Community Property',
  Joint: 'Joint Tenants with Right to Survivorship',
  Tenants: 'Tenants in Common',
}

export const CompanyTypeOptions: Record<string, string> = {
  LLC: 'Limited Liability Company',
  LP: 'Limited Partnership',
  Partnership: 'General Partnership',
  Corp: 'Corporation',
  'Revocable Trust': 'Revocable Trust',
  Individual: 'Individual',
}

export const accountDetailsInputs = (type: AccountType | null, needAccountType = false) => {
  const accountTypeInput: InputType = {
    inputType: 'select0',
    title: 'Account Type',
    options: AccountTypeText,
    required: true,
    hasDefaultOption: true,
  }

  if (!type)
    return {
      accountType: accountTypeInput,
    }

  let inputs: Record<string, InputType> = {
    // isIndividualIncome: {
    //   title:
    //     'Your individual income is $200K or more (or $300K or more with a spouse or partner) for the last two years',
    //   inputType: 'togglebutton',
    //   textLeft: true,
    //   className: 'border-b mb-2 pb-1',
    //   required: true,
    // },
    // isCurrentNetWorth: {
    //   title: 'Your net worth is greater than $1M (excluding your primary residence)',
    //   inputType: 'togglebutton',
    //   textLeft: true,
    //   className: 'border-b mb-2 pb-1',
    //   required: true,
    // },
    // isHoldSeries: {
    //   title: 'You hold a FINRA Series 7, Series 65, or Series 82 license in good standing',
    //   inputType: 'togglebutton',
    //   textLeft: true,
    //   className: 'border-b mb-2 pb-1',
    //   required: true,
    // },
    netWorth: {
      title: 'Total Investable Assets',
      placeholder: 'Select Total Investable Assets',
      inputType: 'select0',
      options: {
        '0': 'Up to $500K',
        '500000': '$500K - $1M',
        '1000000': '$1M - $5M',
        '5000000': 'More than $5M',
      },
      hasDefaultOption: true,
      defaultOptionText: 'Select',
      required: true,
    },
    investmentRange: {
      title: 'Typical Investment Range',
      placeholder: 'Select Typical Investment Range',
      inputType: 'select0',
      options: {
        '0': 'Up to $25K',
        '25000': '$25K - $100K',
        '100000': '$100K - $250K',
        '250000': 'More than $250K',
      },
      hasDefaultOption: true,
      defaultOptionText: 'Select',
      required: true,
    },
  }

  const options: Record<string, Record<string, InputType>> = {
    [AccountType.Individual]: {
      formOfOwnership: {
        inputType: 'select0',
        title: 'Form of Ownership',
        options: OwnershipFormOptions,
        hasDefaultOption: true,
        required: true,
      },
    },
    [AccountType.Ira]: {
      IRA_Type: {
        title: 'IRA Type',
        placeholder: 'Type of IRA',
        inputType: 'select0',
        hasDefaultOption: true,
        allowDefaultOption: false,
        defaultOptionText: 'Select',
        options: IRAOptions,
        error: '',
        required: true,
        visible: true,
      },
      LLC_Name: {
        title: 'IRA Name',
        placeholder: 'Full Name of IRA',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
      LLC_EIN: {
        title: 'LLC EIN',
        placeholder: 'XX-XXXXXXX',
        inputType: 'text0',
        type: 'ein',
        required: true,
        visible: true,
      },
      CustodianName: {
        title: 'Custodian Name',
        placeholder: 'Full Name of Custodian',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
      CustodianContact: {
        title: 'Custodian Contact',
        placeholder: 'Email / Phone',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
      IRA_Account_Number: {
        title: 'IRA Account Number',
        placeholder: 'IRA Account Number',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
    },
    [AccountType.Trust]: {
      legalName: {
        title: 'Name of Trust',
        placeholder: 'Full legal name of trust',
        inputType: 'text0',
        type: 'text',
        required: true,
      },
      typeOfTrust: {
        title: 'Trust Type',
        placeholder: 'Type of Trust',
        inputType: 'text0',
        type: 'text',
        required: true,
      },
      dateTrustCreated: {
        title: 'Date of Trust Created',
        inputType: 'text0',
        type: 'date',
        required: true,
      },
    },
    company: {},
  }

  inputs = {
    ...inputs,
    ...(options[type] || {}),
  }

  if (needAccountType)
    return {
      accountType: accountTypeInput,
      ...inputs,
    }
  if (type !== AccountType.ArticleManager) {
    return inputs || {}
  } else {
    return {}
  }
}

export const AccountTypeSection = ({
  data = null,
  onSubmit: _onSubmit,
}: {
  data: Record<string, any> | null
  onSubmit: Function
}) => {
  const [accountType, setAccountType] = useState<AccountType | null>(data ? data.accountType : null)
  const [inputStates, setInputStates] = useState<Record<string, InputType>>({})

  useEffect(() => {
    if (!accountType) setInputStates({})
    else {
      const inputStates = accountDetailsInputs(accountType)
      if (data) {
        Object.keys(inputStates).forEach((key) => {
          inputStates[key].value = data[key]
        })
      }
      setInputStates(inputStates)
    }
  }, [accountType, data])

  const isDisabled = useMemo(() => {
    if (accountType == AccountType.Company && inputStates.isFundAccredit && !inputStates.isFundAccredit.value)
      return true
    return false
  }, [inputStates])

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputStates)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputStates(newState)
  }

  const onSubmit = () => {
    const { hasError, data, newInputStates } = validateInputs(inputStates)
    if (hasError) {
      setInputStates(newInputStates)
      return
    }
    _onSubmit({
      accountType,
      ...data,
    })
  }

  const renderAccountForm = () => {
    if (!accountType) return null

    return (
      <div className="p-1">
        <div className="flex gap-2 mb-4 items-baseline">
          <span className="text-lg font-medium capitalize">{accountType} Account</span>
          <button
            className="hover:text-indigo-800 text-indigo-500 font-medium text-sm"
            onClick={() => setAccountType(null)}
          >
            Change Account Type
          </button>
        </div>

        {Object.keys(inputStates).map((key) => (
          <div className="mb-4" key={key}>
            {RenderInput({
              input: inputStates[key],
              Key: key,
              onChange,
            })}
          </div>
        ))}

        <Button full color="indigo" onClick={onSubmit} disabled={isDisabled}>
          Continue
        </Button>
      </div>
    )
  }

  return (
    <>
      {!accountType && (
        <div className="p-1">
          <p className="font-medium text-sm mb-2">Select an account type</p>
          <div className="grid grid-cols-2 gap-4">
            {Object.keys(accountTypeOptions).map((key) => {
              const options = accountTypeOptions[key]
              const isActive = accountType == key

              return (
                <div
                  className={`border border-gray-300 text-center rounded-md py-4 px-2 hover:shadow-lg cursor-pointer ${
                    isActive ? 'ring-4 ring-indigo-400' : ''
                  }`}
                  onClick={() => setAccountType(key as AccountType)}
                  key={key}
                >
                  <img className="h-16 text-indigo-500 mx-auto mb-2" src={options.icon} />
                  <p className="text-md font-medium mb-2">{options.title}</p>
                  <p className="text-xs">{options.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {accountType && renderAccountForm()}
    </>
  )
}
