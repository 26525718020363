import { ArrowRightIcon, CheckIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline'
import { AccountType, type InputType, IRADetails, MinInvestmentAmount, MinInvestmentDays } from 'config'
import { cloneDeep } from 'lodash'
import moment from 'moment-timezone'
import type { DbBalance } from 'pages/Payment'
import { ProfileMenuTitles } from 'pages/Profile'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { ProgressBar } from 'stories/components'
import {
  formatDateLong,
  InputConvert,
  normalizeNumber,
  removeComma,
  RenderInput,
  thousandSeperator,
  validateInputs,
} from 'utils'

import { remainingLoanTerm } from '../InvestCard'
import type { Loan } from '../types'

const defaultInputs = (): Record<string, InputType> => {
  return {
    amount: {
      inputType: 'text0',
      type: 'thousandSepNoDecimal',
      title: '',
      placeholder: 'Minimum $5,000',
      className: 'bg-gray-100 text-xl',
      hasIcon: true,
      icon: <CurrencyDollarIcon className="w-4 h-4" />,
      required: true,
    },
    investAs: {
      inputType: 'select0',
      options: [],
      title: '',
      hasDefaultOption: true,
      placeholder: '- Select -',
      defaultOptionText: '- Select -',
      required: true,
    },
  }
}

export const InvestInDeal = ({
  isOpen,
  loan,
  data,
  balance,
  onNext,
  onDeposit,
}: {
  isOpen: boolean
  loan: Loan
  data: Record<string, any>[]
  balance: DbBalance | null
  onNext: Function
  onDeposit: Function
}) => {
  const profile = useSelector((state: RootState) => state.auth.profile)

  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const [showDeposit, setShowDeposit] = useState(false)

  const investorName = useMemo(() => {
    if ([AccountType.Trust].includes(profile.accountType)) {
      return (profile.accountDetails as any)?.legalName || profile.name
    } else if ([AccountType.Ira].includes(profile.accountType)) {
      return (profile.accountDetails as IRADetails)?.LLC_Name || profile.name
    } else return profile.name
  }, [profile])

  const entities = useMemo(() => {
    let rlt: string[] = []

    if ([AccountType.Individual].includes(profile.accountType)) {
      rlt = profile.entities.map((item) => item.entityName)
      rlt.unshift(investorName)
    } else if ([AccountType.Company].includes(profile.accountType)) {
      rlt = profile.entities.map((item) => item.entityName)
    } else rlt = [investorName]

    return rlt
  }, [profile, investorName])

  const remained = useMemo(() => {
    const remained = remainingLoanTerm(loan.maturityDate)
    if (!remained) return null
    const remainedArr = remained.split(' ')
    // if (remainedArr[0] == 'a') remainedArr[0] = '1'
    return remainedArr
  }, [loan])

  const remainingAmount = useMemo(() => {
    return loan.loanAmount - loan.yldInvestment - loan.funds - loan.constructionBalance
  }, [loan])

  const values: Record<string, any> = {
    'Loan to ARV': `${loan.asIsLTV}%`,
    'Investment Offering': `$${thousandSeperator(loan.loanAmount)}`,
    'Min. Investment': `$${thousandSeperator(MinInvestmentAmount)}`,
    'Remaining Available Opportunity': `$${thousandSeperator(remainingAmount)}`,
    // Term: `${loan.originalLoanTerm} Months`,
    'Underlying Asset': loan.propertyType,
    Guarantee: loan.gurantee,
    'Underlying Security': loan.lienType,
  }

  useEffect(() => {
    const newState = cloneDeep(inputs)

    if ([AccountType.Company, AccountType.Individual].includes(profile.accountType)) {
      newState.investAs.inputType = 'select0'
      newState.investAs.options = entities
      newState.investAs.disabled = false
      if (!!entities.length) {
        if (profile.accountType === AccountType.Individual) newState.investAs.value = entities[0]
        else {
          const defaultEntity = profile.entities.find((item) => !!item?.isDefault)
          newState.investAs.value = !!defaultEntity ? defaultEntity.entityName : entities[0]
        }
      }
    } else {
      newState.investAs.inputType = 'text0'
      newState.investAs.value = investorName
      newState.investAs.disabled = true
      newState.investAs.value = investorName
    }

    if (data[0]) {
      newState['amount'].value = InputConvert(newState['amount'], data[0]['amount'])
      newState['investAs'].value = InputConvert(newState['investAs'], data[0]['investAs'])
    }

    setInputs(newState)
  }, [data, balance, entities, investorName])

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputs)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputs(newState)
  }

  const onSubmit = () => {
    const days = moment(loan.maturityDate).diff(Date.now(), 'days')
    if (days < MinInvestmentDays) {
      toast(`This loan will be finished on ${formatDateLong(loan.maturityDate)}. Please invest on another loans.`, {
        type: 'warning',
      })
      return
    }

    if (!isOpen) {
      onNext()
      return
    }
    const { hasError, data, newInputStates } = validateInputs(inputs)
    if (hasError) {
      setInputs(newInputStates)
      return
    }

    data.investAs = inputs.investAs.value
    data['amount'] = removeComma(data['amount'])
    const amount = data['amount']
    let hasError1 = false

    const investAs = data.investAs

    if ([AccountType.Individual, AccountType.Company].includes(profile.accountType)) {
      const targetEntity = profile.entities.find((item) => item.entityName === investAs)
      if (!!targetEntity && !targetEntity?.isApproved) {
        hasError1 = true
        newInputStates.investAs.error = `The Certification of Formation is not approved for this Entity.`
      }
    }

    if (!hasError1 && amount + Number(loan.funds) > loan.loanAmount) {
      newInputStates.amount.error = `The Loan Amount will be exceed after you invest. Please reduce the investment amount or invest to another loan.`
      hasError1 = true
    }

    if (!hasError1 && amount < MinInvestmentAmount) {
      newInputStates.amount.error = `The amount should be greater than $${normalizeNumber(MinInvestmentAmount)}`
      hasError1 = true
    }

    if (!hasError1) {
      const available = (balance && balance.available) || 0
      if (available < amount) {
        newInputStates.amount.error = `The amount should not exceed your available balance of $${normalizeNumber(
          available,
        )}.`
        hasError1 = true
        setShowDeposit(true)
      }
    }

    if (!hasError1) {
      if (remainingAmount < amount) {
        newInputStates.amount.error = `The amount should not exceed the remaining available opportunity of $${normalizeNumber(
          remainingAmount,
        )}.`
        hasError1 = true
      }
    }

    if (hasError1) {
      setInputs(newInputStates)
      return
    }

    setShowDeposit(false)
    onNext(data)
  }

  return (
    <div className="w-full">
      <div className="px-6 -mt-14 relative">
        <div className="flex justify-center text-center gap-4 px-12 border-b pb-6">
          <div className="rounded-xl bg-white p-6 border w-40">
            <p className="text-4xl mb-6 font-semibold">{loan.ytm}%</p>
            <p className="text-desc">Annual Return</p>
          </div>
          <div className="rounded-xl bg-white p-6 border w-40">
            <div className="flex gap-2 mb-6 font-semibold justify-center items-end">
              <p className="text-4xl">{remained && remained[0]}</p>
              <p className="text-md">{remained && remained[1]}</p>
            </div>
            <p className="text-desc">Time Remaining</p>
          </div>
        </div>
      </div>

      <div className="font-semibold text-sm py-4">
        {Object.keys(values).map((key, index) => (
          <div className={`${index % 2 == 1 ? 'bg-gray-50' : ''} px-6 py-1.5 justify-between flex`} key={index}>
            <p>{key}</p>
            <p>{values[key]}</p>
          </div>
        ))}
      </div>

      <div className="px-6 text-sm font-semibold mb-4">
        <div className="flex gap-1 mb-2">
          <p className="text-indigo-500">${thousandSeperator(Number(loan.funds) + Number(loan.yldInvestment))}</p>
          <p className="flex-1">Funded</p>
          <p className="">${thousandSeperator(remainingAmount)}</p>
        </div>
        <ProgressBar height={5} progress={loan.progress} />
      </div>

      {isOpen && (
        <div className="px-6 text-sm">
          <p className="font-semibold mb-2">Start Investing</p>

          <div className="flex justify-between items-center mb-2">
            <p className="text-desc">Invest as</p>
            {[AccountType.Company, AccountType.Individual].includes(profile.accountType) && (
              <Link
                to={`/profile#${
                  profile.accountType === AccountType.Company
                    ? ProfileMenuTitles.CERT_FORMATION
                    : ProfileMenuTitles.PROFILE
                }`}
                className="flex items-center gap-1 hover:underline hover:cursor-pointer text-indigo-500"
              >
                <span>Go to Entities</span>
                <ArrowRightIcon className="w-3 h-3" />
              </Link>
            )}
          </div>

          <RenderInput Key="investAs" input={inputs.investAs} onChange={onChange} />

          <p className="text-desc mb-2">
            Investment amount.{' '}
            <span>(Available: ${normalizeNumber(Math.min(balance ? balance.available : 0, remainingAmount))})</span>
          </p>
          <RenderInput Key="amount" input={inputs['amount']} onChange={onChange} />
        </div>
      )}

      <div className="px-6 text-sm">
        {showDeposit && (
          <button
            type="button"
            onClick={() => onDeposit()}
            className={`text-black w-full ${
              isOpen
                ? 'bg-blue-500 hover:bg-blue-400 focus:ring-blue-300'
                : 'bg-indigo-100 hover:bg-indigo-300 focus:ring-indigo-300'
            } focus:ring-4 focus:outline-none dark:focus:ring-blue-800 font-semibold rounded-lg text-sm items-center px-5 py-2.5 text-center text-white flex justify-center gap-2 mb-3`}
          >
            <CurrencyDollarIcon className="w-4 h-4" />
            Deposit Additional Funds
          </button>
        )}
        <button
          type="button"
          onClick={onSubmit}
          className={`text-black w-full ${
            isOpen
              ? 'bg-light-green hover:bg-green-300 focus:ring-green-300'
              : 'bg-indigo-100 hover:bg-indigo-300 focus:ring-indigo-300'
          } focus:ring-4 focus:outline-none dark:focus:ring-green-800 font-semibold rounded-lg text-sm items-center px-5 py-2.5 text-center flex justify-center gap-2`}
        >
          {isOpen ? (
            <>
              <CheckIcon className="w-4 h-4" />
              Invest in Deal
            </>
          ) : (
            'Investing has been Closed'
          )}
        </button>
      </div>
    </div>
  )
}
