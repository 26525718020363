import {
  ArrowLeftIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/24/outline'
import { authUpdateProfile } from 'actions'
import {
  AccountStatus,
  AccountType,
  CustomInput,
  fullStates,
  InputType,
  isArticleManager,
  isManager,
  states,
} from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { accountTypeOptions } from 'pages/Auth/SignUp/AccountType'
import { IRAOptions, IRAType } from 'pages/Auth/SignUp/Section'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { getProfile, sendConfirmEmailToSelf, updateAccountDetails, updateProfile, validatePhone } from 'services/apis'
import { Button, Checkbox } from 'stories/components'
import { countries } from 'stories/components/Input/PhoneSelector'
import { firstName, InputConvert, lastName, RenderInput, useTitle, validateInputs } from 'utils'

import { CertFormationTab } from './CertFormationTab'
import { PhoneVerification } from './PhoneVerification'
import { ProfileMenuTitles } from './Profile'

const defaultInputs = (accountType: AccountType): Record<string, InputType> => {
  const options: Record<string, Record<string, InputType>> = {
    [AccountType.Trust]: {
      legalName: {
        title: 'Trust Name',
        placeholder: 'Full Trust Name',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
      typeOfTrust: {
        title: 'Trust Type',
        placeholder: 'Type of Trust',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
      dateTrustCreated: {
        title: 'Date of Trust Created',
        inputType: 'text0',
        type: 'date',
        required: true,
        visible: true,
      },
    },
    [AccountType.Ira]: {
      IRA_Type: {
        title: 'IRA Type',
        placeholder: 'Type of IRA',
        inputType: 'select0',
        hasDefaultOption: true,
        allowDefaultOption: false,
        defaultOptionText: 'Select',
        options: IRAOptions,
        error: '',
        required: true,
        visible: true,
      },
      LLC_Name: {
        title: 'IRA Name',
        placeholder: 'Full Name of IRA',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
      LLC_EIN: {
        title: 'LLC EIN',
        placeholder: 'XX-XXXXXXX',
        inputType: 'text0',
        type: 'ein',
        required: true,
        visible: true,
      },
      // LLC_Bank_Account: {
      //   title: 'LLC Bank Account',
      //   placeholder: 'LLC Bank Account Information',
      //   inputType: 'text0',
      //   type: 'text',
      //   visible: true,
      // },
      // certificateFormation: {
      //   inputType: 'file',
      //   title: 'LLC Document',
      //   required: false,
      //   visible: true,
      //   multiple: true,
      // },
      CustodianName: {
        title: 'Custodian Name',
        placeholder: 'Full Name of Custodian',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
      CustodianContact: {
        title: 'Custodian Contact',
        placeholder: 'Email / Phone',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
      IRA_Account_Number: {
        title: 'IRA Account Number',
        placeholder: 'IRA Account Number',
        inputType: 'text0',
        type: 'text',
        required: true,
        visible: true,
      },
    },
  }

  return options[accountType]
}

export const AccountDetails = () => {
  useTitle('Account Information')

  const profile = useSelector((state: RootState) => state.auth.profile)
  const isIndividual = profile.accountType == AccountType.Individual
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(false)
  const [sentEmail, setSentEmail] = useState(false)
  const [edit, setEdit] = useState(false)
  const [modal, setModal] = useState('')

  const baseData = useMemo<Record<string, any>>(() => {
    const rlt: Record<string, any> = {
      'Name:': profile.name,
      'Phone:': profile.phone,
      'Accredited Investor?': profile.isAccreditedInvestor == '1' ? 'Yes' : 'No',
      'Foreign Investor?': profile.isForeignInvestor == '1' ? 'Yes' : 'No',
      'Email:': profile.email,
      'Country:': countries[profile.country],
      'State:': profile.accountDetails.state ? fullStates[profile.accountDetails.state] : '',
      'City:': profile.accountDetails.city,
    }
    if (profile.country !== 'US') delete rlt['State:']
    return rlt
  }, [profile])

  const { accountType } = profile
  const isAdmin = isManager(accountType)
  const isArticle = isArticleManager(accountType)
  // const isUpdatable = profile.status == AccountStatus.EmailVerified
  const isEmailVerified = profile.emailIsVerified
  const isPhoneVerified = profile.phoneIsVerified

  const renderAccountType = (value: string, onChange: Function, input: CustomInput) => {
    const { error } = input

    return (
      <div className="mb-8">
        <p className="text-gray-400 mb-2">Please select one:</p>
        <div className="flex flex-col gap-2">
          {Object.keys(accountTypeOptions).map((key) => {
            const options = accountTypeOptions[key]
            const isActive = value == key

            return (
              <div className={`border rounded-md p-2 ${isActive ? 'bg-indigo-500' : ''}`} key={key}>
                <Checkbox
                  id={key}
                  checked={isActive}
                  onClick={() => !isActive && onChange(key)}
                  title={
                    <div>
                      <p className={isActive ? 'text-white' : ''}>{options.title}</p>
                      <p className={`text-sm ${isActive ? 'text-gray-100' : 'text-gray-600'}`}>{options.description}</p>
                    </div>
                  }
                />
              </div>
            )
          })}
        </div>
        {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1 mt-1">{error}</p>}
      </div>
    )
  }

  const [inputs, setInputs] = useState<Record<string, Record<string, InputType>>>({
    accountDetails: {
      // ...accountDetailsInputs(accountType, true),
      accountType: {
        inputType: 'custom',
        title: '',
        render: renderAccountType,
        required: true,
        value: profile.accountType,
      },
      ...defaultInputs(accountType),
    },
    // investorProfile: investorProfileInputs(),
  })

  useEffect(() => {
    setLoading(true)
    getProfile()
      .then((profile) => dispatch(authUpdateProfile(profile)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    // 'investorProfile'
    ;['accountDetails'].forEach((section) =>
      Object.keys((profile as any)[section]).forEach((key) => {
        if (newInputs[section][key]) newInputs[section][key].value = (profile as any)[section][key]
      }),
    )
    // ;(newInputs.accountDetails.accountType as any).options = NormalAccountTypes
    newInputs.accountDetails.accountType = {
      inputType: 'custom',
      title: '',
      render: renderAccountType,
      required: true,
      value: profile.accountType,
    }
    checkAccountDetailsVisible(newInputs)
    setInputs(newInputs)
  }, [profile])

  const checkAccountDetailsVisible = (nInputs: Record<string, Record<string, InputType>>) => {
    if (nInputs.accountDetails.accountType.value === AccountType.Ira) {
      if (nInputs.accountDetails.IRA_Type.value === IRAType.LLC) {
        nInputs.accountDetails.LLC_Name.visible = true
        nInputs.accountDetails.LLC_EIN.visible = true
        nInputs.accountDetails.CustodianName.visible = false
        nInputs.accountDetails.CustodianContact.visible = false
        nInputs.accountDetails.IRA_Account_Number.visible = false
      } else {
        nInputs.accountDetails.LLC_Name.visible = false
        nInputs.accountDetails.LLC_EIN.visible = false
        nInputs.accountDetails.CustodianName.visible = true
        nInputs.accountDetails.CustodianContact.visible = true
        nInputs.accountDetails.IRA_Account_Number.visible = true
      }
    }
  }

  const onChange = (section: string, key: string, value: string) => {
    // if (!isUpdatable) return

    let newState = cloneDeep(inputs)
    newState[section][key].value = InputConvert(newState[section][key], value)
    newState[section][key].error = ''

    if (section == 'accountDetails' && key == 'accountType') {
      if (value == inputs[section][key].value) return

      const detailsInputs: Record<string, InputType> = {
        accountType: {
          inputType: 'custom',
          title: '',
          render: renderAccountType,
          value,
          required: true,
        },
        ...defaultInputs(value as AccountType),
      }

      Object.keys(detailsInputs).forEach((key) => {
        if (!newState['accountDetails'][key]) return
        detailsInputs[key].value = newState['accountDetails'][key].value
      })
      newState['accountDetails'] = detailsInputs
    }

    checkAccountDetailsVisible(newState)

    setInputs(newState)
  }

  const onSubmit = async () => {
    const {
      hasError: accountHasError,
      data: accountDetails,
      newInputStates: accountDetailsStates,
    } = validateInputs(inputs['accountDetails'])
    // const {
    //   hasError: investorHasError,
    //   data: investorProfile,
    //   newInputStates: investorProfileStates,
    // } = validateInputs(inputs['investorProfile'])

    if (accountHasError) {
      // || investorHasError) {
      setInputs({
        ...inputs,
        accountDetails: accountDetailsStates,
        // investorProfile: investorProfileStates,
      })
      return
    }

    setLoading(true)
    const newAccountDetails: Record<string, any> = {
      ...profile.accountDetails,
      ...accountDetails,
    }

    Object.keys(newAccountDetails).forEach((key) => {
      if (typeof newAccountDetails[key] != 'boolean' || key == 'isFundAccredit') return
      newAccountDetails[key] = newAccountDetails[key] ? '1' : '0'
    })

    updateProfile({ accountType: accountDetails.accountType, accountDetails: newAccountDetails, investorProfile: {} })
      .then(() => {
        const isProfileCompleted = profile.status == AccountStatus.ProfileCompleted
        toast(
          isProfileCompleted
            ? 'Your profile has been updated.'
            : 'Congratulations, your account information has been complete, please continue to the identity verification.',
          {
            type: 'success',
          },
        )

        dispatch(
          authUpdateProfile({
            ...profile,
            accountType: accountDetails.accountType,
            accountDetails: newAccountDetails as any,
            status: isProfileCompleted ? profile.status : AccountStatus.ProfileCompleted,
          }),
        )
      })
      .finally(() => {
        setLoading(false)
        navigate(`/profile#${ProfileMenuTitles.KYC}`)
      })
  }

  const onSendConfirmEmail = () => {
    setLoading(true)
    sendConfirmEmailToSelf()
      .then(() => {
        toast('A confirmation email has been sent.', { type: 'info' })
        setSentEmail(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="text-base text-gray-600">
      <div className={`flex ${edit ? 'justify-start' : 'justify-end'}`}>
        {edit ? (
          <div
            className="flex justify-center items-center gap-2 hover:cursor-pointer hover:underline text-gray-700 mb-4"
            onClick={() => setEdit(false)}
          >
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="text-sm">Go Back</span>
          </div>
        ) : (
          <Button size="sm" className="py-1 px-2" onClick={() => setEdit(true)}>
            Edit Profile
          </Button>
        )}
      </div>

      {edit ? (
        <_AccountDetails setEdit={setEdit} />
      ) : (
        <div className="pb-4 mb-4">
          {Object.keys(baseData).map((key) => (
            <div className="grid grid-cols-7 gap-2 items-center my-2" key={key}>
              <p className="col-span-2">{key}</p>

              <div className="flex items-center flex-wrap gap-2 col-span-5 break-all">
                <p className={`font-medium ${key != 'Email:' && 'capitalize'} italic`}>{baseData[key]}</p>

                {key === 'Phone:' && isPhoneVerified ? (
                  <ShieldCheckIcon className="w-5 h-5 text-lime-500 hover:cursor-pointer" />
                ) : key === 'Phone:' ? (
                  <Tooltip content="Verify">
                    <ShieldExclamationIcon
                      className="w-7 h-7 text-red-500 hover:cursor-pointer hover-shadow1 p-1 transition-all duration-200"
                      onClick={async () => {
                        setLoading(true)
                        try {
                          await validatePhone({ email: profile.email, phone: profile.phone, checkDuplication: false })
                          setModal('verifyPhone')
                        } catch (error) {}
                        setLoading(false)
                      }}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}

                {key === 'Email:' && isEmailVerified ? (
                  <ShieldCheckIcon className="w-5 h-5 text-lime-500 hover:cursor-pointer" />
                ) : key === 'Email:' ? (
                  <ShieldExclamationIcon className="w-5 h-5 text-red-500 hover:cursor-pointer" />
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}

          {!isEmailVerified && sentEmail && (
            <div className="grid grid-cols-7 gap-2">
              <div className="col-span-2"></div>
              <div className="col-span-5">
                <div className="text-yellow-500 text-sm">
                  We have just sent a verification email. Please check your email to verify. If you do not see the email
                  in your inbox, please check your spam or junk folder.
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {!edit && (
        <>
          <div className="relative">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              {/* <div /> */}
              {isEmailVerified && isPhoneVerified && !isAdmin && !isArticle && (
                <>
                  <div>
                    <p className="font-bold border-b-2 mb-2">Account Type</p>
                    {RenderInput({
                      input: inputs.accountDetails.accountType,
                      Key: 'accountType',
                      onChange: (key: string, v: any) => onChange('accountDetails', 'accountType', v),
                    })}
                  </div>

                  {[AccountType.Trust, AccountType.Ira].includes(inputs.accountDetails.accountType.value) && (
                    <div>
                      <p className="font-bold border-b-2 mb-2">Account Details</p>
                      {Object.keys(inputs.accountDetails).map((key, index) => {
                        if (key === 'accountType') return <></>
                        const input = inputs.accountDetails[key]
                        if (!input.visible) return <></>
                        return (
                          <span key={index}>
                            <RenderInput
                              input={input}
                              Key={key}
                              onChange={(key: string, v: any) => onChange('accountDetails', key, v)}
                            />
                          </span>
                        )
                      })}
                    </div>
                  )}
                </>
              )}
            </div>

            {(!isEmailVerified || !isPhoneVerified) && (
              <div className="flex justify-center">
                <div className="border border-yellow-500 bg-yellow-50 text-yellow-500 text-center mt-4 p-3 rounded min-w-[300px] w-1/2">
                  Please verify your {!isEmailVerified ? 'email' : 'phone'} before continuing.
                </div>
              </div>
            )}

            {isEmailVerified && isPhoneVerified && !isArticle && (
              <div className="mt-4 w-full">
                <div className="w-fit mx-auto">
                  <Button onClick={onSubmit} loading={isLoading} full>
                    Continue account set up
                  </Button>
                </div>
              </div>
            )}

            {/* {!isUpdatable && <div className="absolute w-full top-0 left-0 bottom-0 bg-white/20 z-[9999]" />} */}
          </div>

          {!isEmailVerified && (
            <div className="mt-4 w-full">
              <div className="w-60 mx-auto">
                <Button full loading={isLoading} onClick={onSendConfirmEmail}>
                  {sentEmail ? 'Resend' : 'Verify Now'}
                </Button>
              </div>
            </div>
          )}
        </>
      )}

      {isIndividual && isEmailVerified && isPhoneVerified && (
        <div className="mt-6">
          <p className="font-bold border-b-2 mb-2">Entity Setup</p>
          <CertFormationTab />
        </div>
      )}

      {modal === 'verifyPhone' && (
        <PhoneVerification
          data={profile}
          isOpen={modal === 'verifyPhone'}
          onSuccess={() => {
            dispatch(
              authUpdateProfile({
                ...profile,
                phoneIsVerified: true,
              }),
            )
            setModal('')
          }}
          onClose={() => setModal('')}
        />
      )}
    </div>
  )
}

export const accreditedTooltipContent = (
  <div className="w-[80vw] xs:w-96 relative">
    <p className="mb-2">Do you have any of one of these requirements?</p>
    {[
      'An annual income of at least $200,000 for the past two years (or $300,000 with a spouse).',
      'Net worth exceeding $1 million, excluding primary residence.',
      'Hold a professional designation, like a Series 7 or Series 65 license.',
    ].map((text, index) => (
      <p key={index}>
        {index + 1}. {text}
      </p>
    ))}
  </div>
)

export const accountBaseInputs = (): Record<string, InputType> => {
  return {
    firstName: {
      title: 'First Name',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
      visible: true,
    },
    lastName: {
      title: 'Last Name',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
      visible: true,
    },
    phone: {
      title: 'Phone',
      placeholder: '+1 215 888 8888',
      inputType: 'text0',
      type: 'phone',
      required: true,
      visible: true,
    },
    email: {
      title: 'Email',
      placeholder: 'name@example.com',
      inputType: 'text0',
      type: 'email',
      error: '',
      required: true,
      visible: true,
    },
    country: {
      title: 'Country',
      placeholder: '',
      inputType: 'select0',
      options: countries,
      error: '',
      required: true,
      visible: true,
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
    },
    state: {
      title: 'State',
      placeholder: 'Select',
      inputType: 'select0',
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
      options: states,
      error: '',
      required: true,
      visible: true,
    },
    city: {
      title: 'City',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
      visible: true,
    },
    isAccreditedInvestor: {
      title: (
        <>
          Do you qualify as an accredited investor?
          <Tooltip className="accredited-tooltip" content={accreditedTooltipContent}>
            <InformationCircleIcon className="w-4 h-4 cursor-pointer" />
          </Tooltip>
        </>
      ) as any,
      inputType: 'radio',
      options: { 1: 'Yes', 0: 'No' },
      innerClassName: 'flex-row-reverse justify-end',
      required: true,
      visible: true,
      className: 'font-medium text-base mb-4',
    },
    // isForeignInvestor: {
    //   title: (
    //     <p className="flex gap-2 items-center">
    //       Are you a foreign Investor (Non US) ?
    //       {/* <Tooltip className="accredited-tooltip" content={accreditedTooltipContent}>
    //         <InformationCircleIcon className="w-4 h-4 cursor-pointer" />
    //       </Tooltip> */}
    //     </p>
    //   ) as any,
    //   inputType: 'radio',
    //   options: { 1: 'Yes', 0: 'No' },
    //   innerClassName: 'flex-row-reverse justify-end',
    //   required: true,
    //   className: 'font-medium text-base',
    // },
  }
}

interface IProps {
  setEdit: (value: boolean) => void
}
export const _AccountDetails = (props: IProps) => {
  const profile = useSelector((state: RootState) => state.auth.profile)
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [inputs, setInputs] = useState(accountBaseInputs())

  useEffect(() => {
    setEdit(false)
  }, [])

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    newInputs.firstName.value = firstName(profile.name)
    newInputs.lastName.value = lastName(profile.name)
    newInputs.email.value = profile.email
    newInputs.phone.value = profile.phone
    newInputs.country.value = profile.country
    newInputs.isAccreditedInvestor.value = profile.isAccreditedInvestor
    newInputs.state.value = profile.accountDetails.state
    newInputs.city.value = profile.accountDetails.city

    if (profile.country === 'US') {
      newInputs.state.required = true
      newInputs.state.visible = true
    } else {
      newInputs.state.required = false
      newInputs.state.visible = false
    }
    setInputs(newInputs)
  }, [profile])

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputs)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''

    if (key === 'country') {
      if (value === 'US') {
        newState.state.required = true
        newState.state.visible = true
      } else {
        newState.state.required = false
        newState.state.visible = false
      }
    }

    setInputs(newState)
    setEdit(true)
  }

  const onSubmit = () => {
    const { hasError, data, newInputStates } = validateInputs(inputs)
    if (hasError) {
      setInputs(newInputStates)
      return
    }

    data.name = `${data.firstName} ${data.lastName}`
    delete data.firstName
    delete data.lastName

    setLoading(true)
    updateAccountDetails(data)
      .then(() => {
        const emailIsVerified = profile.emailIsVerified
          ? profile.email === data.email
            ? true
            : false
          : profile.emailIsVerified
        const phoneIsVerified = profile.phoneIsVerified
          ? profile.phone === data.phone
            ? true
            : false
          : profile.phoneIsVerified
        const isForeigner = data.country === 'US' ? '0' : '1'
        dispatch(
          authUpdateProfile({
            ...profile,
            name: data.name,
            email: data.email,
            phone: data.phone,
            country: data.country,
            isAccreditedInvestor: data.isAccreditedInvestor,
            isForeignInvestor: isForeigner,
            emailIsVerified,
            phoneIsVerified,
            accountDetails: {
              ...profile.accountDetails,
              city: data.city,
              state: data.state,
            },
          }),
        )
        setLoading(false)
        setEdit(false)
        props.setEdit(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <div className="text-sm">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {Object.keys(inputs).map((key) => {
          const input = inputs[key]
          if (!input.visible) return <></>
          return <RenderInput Key={key} input={input} onChange={onChange} key={key} />
        })}
      </div>

      {edit && (
        <div className="flex justify-center">
          <Button onClick={onSubmit} loading={isLoading}>
            Update
          </Button>
        </div>
      )}
    </div>
  )
}
